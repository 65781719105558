import * as React from "react";
import constants from "constants/index";
import Meta from "../../../components/meta";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import { graphql } from "gatsby";
import Content from "../../../components/layout/Content/Content";
import Layout from "../../../components/layout/Layout";
import HomeFeatured from "../../../components/pages/home/HomeFeatured";
import HomeExamples from "../../../components/pages/home/HomeExamples";
import AiThemingImage from "../../../components/pages/ai/AiThemingImage";
import HeroBigTextBannerWithVideo from "../../../components/pages/solutions/HeroBigTextBannerWithVideo";
import HomeTemplates from "../../../components/pages/home/HomeTemplates";
import HomeFeedback from "../../../components/pages/home/HomeFeedback";
import SectionWithLeftImage from "../../../components/pages/solutions/SectionWithLeftImage";
import HomeAutodesignerLeft from "../../../components/pages/home/HomeAutodesignerLeft";
import HomeAutodesigner from "../../../components/pages/home/HomeAutodesigner";
import Shoutout from "../../../components/pages/signup/Shoutout";

const Container = Styled.div`
  margin: 10rem 0 10rem 0;

  ${Media.greaterThan("medium")`
    margin: 12rem 0 10rem 0;
  `}
`;

const ContentExtended = Styled(Content)`
  margin-top: 9rem;
`;

const Entrepreneurs = ({ data }) => (
  <Layout>
    <Meta
      title="Uizard For Consultants And Agencies | Uizard"
      description="The UI design tool for consultants and agencies. No prior design or development experience required!"
      url="/solutions/consultants/"
    />

    <Container>

      <HeroBigTextBannerWithVideo
        tagline="Consultants & Agencies"
        headline="Design stunning mockups in minutes"
        description="Create stunning visuals in no time at all with the power of AI. Spinning out app, web, and UI design deliverables for your clients has never been so easy."
        imageA={data.prototypingImageA.childImageSharp}
        imageB={data.prototypingImageB.childImageSharp}
        imageAlt="a user linking screens in uizard to design an interactive mobile app prototype"
        link={`https://${constants.APP_DOMAIN}/auth/sign-up`}
        cta="Sign up for free"
        tagAsTitle={true}
        videoName="uizard-user-journey"
        videoH={300}
      />

      <HomeAutodesignerLeft
        headline="Generate prototypes from text with Autodesigner"
        description="Generate design deliverables in minutes using the power of generative AI. Uizard Autodesigner gives you all the tools you need to make your design idea a reality."
        description2="Simply type out your ideas in plain English, click generate, and let AI do the hard work."
        imageA={data.autodesignerImageA.childImageSharp}
        imageB={data.autodesignerImageB.childImageSharp}
        imageAlt="turn text prompts into UI designs with AI"
        videoName="uizard-autodesigner-light"
        link="/autodesigner/"
      />

      <HomeAutodesigner
        headline="Transform screenshots into design deliverables"
        description="Take inspiration from any app, website, or user interface with Uizard Screenshot. With the power of AI, Uizard's Screenshot Scanner converts screen grabs into fully editable designs in a matter of seconds."
        description2="Creating gorgeous design assets for your projects has never been easier."
        imageA={data.hificonversionImageA.childImageSharp}
        imageB={data.hificonversionImageB.childImageSharp}
        imageAlt="turn static screenshots into editable UI mockups"
        videoName="uizard-scan-screenshot"
        link="/screenshot/"
        videoH={460}
      />

      <AiThemingImage
        headline="Generate unique themes with AI"
        description="Use Uizard's Theme Generator to update, edit, and adapt your designs."
        description2="Whether you've kickstarted a project from a text prompt or from a screenshot, you can update the UI theme of your designs to hit client briefs in no time at all."
        imageA={data.styleImageA.childImageSharp}
        imageB={data.styleImageB.childImageSharp}
        link="/design-assistant/"
      />

      <HomeTemplates
        tagline="No tutorial needed"
        headline="Prototyping made easy"
        description="<a:https://uizard.io/prototyping/>UI prototyping</a> made easy. Design interactive prototypes for web apps, software interfaces, and mobile apps in minutes. Enable your clients to take an active part in designing proof-of-concept deliverables in design sprints and product discovery sessions."
        imageA={data.templatesImageA.childImageSharp}
        imageB={data.templatesImageB.childImageSharp}
        imageAlt="Visual showing of a team of users creating a wireframe in Uizard."
      />

      <SectionWithLeftImage
        tagline="Productivity tool"
        headline="Save time and money"
        description="Capture out project requirements in real time during your first client meeting. Use Uizard to align expectations right from the start. If a picture is worth a 1000 words, an interactive mockup is worth a 1000 meetings!"
        imageA={data.foundersDriveTowardSuccessBig.childImageSharp}
        imageB={data.foundersDriveTowardSuccessSmall.childImageSharp}
        imageAlt="A visual showcasing multiple users designing an interactive prototype in Uizard."
      />

      <HomeFeedback
        tagline="Real-time collaboration"
        headline="Get feedback instantly"
        description="Just share a link to your project to start collaborating with your team or get feedback from your clients.
        All from your web browser. No software install required, just pure productivity."
        imageA={data.feedBackImageA.childImageSharp}
        imageB={data.feedBackImageB.childImageSharp}
        imageAlt="Image of people collaborating in real-time in Uizard and leaving written feedback in the form of comments."
      />

      <HomeFeatured />
      <HomeExamples />

      <ContentExtended>
        <Shoutout
          headline="Design. Simplified."
          description="App, web, and UI design made easy with the power of AI."
          cta="Sign up for free"
          imageA={data.shoutoutImageA.childImageSharp}
        />
      </ContentExtended>
    </Container>
  </Layout>
);

export default Entrepreneurs;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    prototypingImageA: file(
      relativePath: { eq: "prototyping/Proto_Cover_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 976, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    prototypingImageB: file(
      relativePath: { eq: "prototyping/Proto_Cover_1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 488, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    templatesImageA: file(
      relativePath: { eq: "wireframing/brainstorming-low-fi-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2560, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    templatesImageB: file(
      relativePath: { eq: "wireframing/brainstorming-low-fi-image-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1016, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    brainstormingImageA: file(
      relativePath: { eq: "wireframing/brainstorming-low-fi-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2560, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    brainstormingImageB: file(
      relativePath: { eq: "wireframing/brainstorming-low-fi-image-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1016, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    conversionImageA: file(
      relativePath: { eq: "home/conversion-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 938, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    conversionImageB: file(
      relativePath: { eq: "home/conversion-image-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 650, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    autodesignerImageA: file(
      relativePath: { eq: "ai/uizard-autodesigner-light-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    autodesignerImageB: file(
      relativePath: { eq: "ai/uizard-autodesigner-light-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    hificonversionImageA: file(
      relativePath: { eq: "ai/HifiConversion-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    hificonversionImageB: file(
      relativePath: { eq: "ai/HifiConversion-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 501, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    wireframeImageA: file(relativePath: { eq: "home/wireframe-image-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 1230, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    foundersDriveTowardSuccessBig: file(
      relativePath: { eq: "founders/Founders_DriveTowardSuccess_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1116, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    foundersDriveTowardSuccessSmall: file(
      relativePath: { eq: "founders/Founders_DriveTowardSuccess_1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 559, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    wireframeImageB: file(relativePath: { eq: "home/wireframe-image-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 670, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    styleImageA: file(relativePath: { eq: "ai/style-image-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 1038, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    styleImageB: file(relativePath: { eq: "ai/style-image-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 1038, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    feedBackImageA: file(relativePath: { eq: "home/feedback-image-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 1462, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    feedBackImageB: file(relativePath: { eq: "home/feedback-image-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 774, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
