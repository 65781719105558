import * as React from "react";
import Styled from "styled-components/macro";
import Image, { GatsbyImageProps } from "gatsby-image";
import Media from "@uizard-io/styled-media-query";
import Headline from "../../texts/Headline";
import Description from "../../texts/Description";
import Content from "../../layout/Content/Content";
import MediaQuery from "../../layout/MediaQuery";
import Button from "../../../components/buttons/Button";

const ImageTop = Styled.div`
    width: 100%;
    height: 20rem;
    background-color: #D8D8D8;
    margin-top: 4rem;
    
    ${Media.greaterThan("medium")`
        margin-top: 0;
        width: 83%;
        height: 25rem;
    `}
`;

const ImageBottom = Styled(Image)`
    width: 100%;
    margin-top: 1.5rem;
`;

const Columns = Styled.div`
    display: flex;
    margin: 8rem 0 8rem 0;
    align-items: center;
    justify-content: space-between;
    flex-direction: column-reverse;
    
    ${Media.greaterThan("medium")`
        flex-direction: row;
        margin: 18rem 0 12rem 0;
    `}
`;

const ColumnText = Styled.div`
    width: 100%;
    
    ${Media.greaterThan("large")`
        padding-right: 9.5rem;
    `}
    
    ${Media.greaterThan("medium")`
        width: 50%;
    `}
`;

const ColumnIllustration = Styled.div`
    width: 35.5rem;

    ${Media.greaterThan("medium")`
        width: 38rem;
        margin-right: 8rem;
    `}
    
     ${Media.greaterThan("large")`
        width: 48rem;
        margin-right: 16.8rem;
    `}
`;

const ExtendedButton = Styled(Button)`
  margin-bottom: 5rem;
  margin-top: 3rem;

  ${Media.greaterThan("medium")`
    margin-bottom: 0;
  `}

  ${Media.lessThan("medium")`
    margin-top: 1rem;
  `}
`;

const NavButtonLink = Styled.a`
`;

type AiThemingImageProps = {
  headline: string;
  description?: string;
  imageA: GatsbyImageProps;
  imageB: GatsbyImageProps;
  link?: string;
  description2?: string;
};

const AiThemingImage = ({
  headline,
  imageA,
  imageB,
  description,
  description2,
  link,
}: AiThemingImageProps) => (
  <Content>
    <Columns>
      <ColumnIllustration>
        <MediaQuery lessThan="medium">
          <ImageBottom
            fluid={"fluid" in imageB ? imageB.fluid : ""}
            alt="Image showcasing Uizard's capability to turn images into customizable design themes."
          />
        </MediaQuery>

        <MediaQuery greaterThan="medium">
          <ImageBottom
            fluid={"fluid" in imageA ? imageA.fluid : ""}
            alt="Image showcasing Uizard's capability to turn images into customizable design themes."
          />
        </MediaQuery>
      </ColumnIllustration>
      <ColumnText>
        <Headline>{headline}</Headline>

        {description && !description2 &&
          <Description>{description}</Description>
        }

        {description && description2 &&
          <Description>{description}<br/><br/>{description2}</Description>
        } 

        {!description && !description2 &&
          <Description>
            Uizard uses powerful deep learning algorithms to generate beautiful
            design themes from the images you upload.
            <br />
            <br />
            Photos, screenshots, mood boards… regardless of where you get
            inspiration from, Uizard can generate a custom-made matching theme
            in no time at all.
          </Description>
        }

        {link && (
          <NavButtonLink href={link}>
            <ExtendedButton variant="primarylight" solid>
              Read more
            </ExtendedButton>
          </NavButtonLink>
        )}
      </ColumnText>
    </Columns>
  </Content>
);

export default AiThemingImage;
