import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Image, { GatsbyImageProps } from "gatsby-image";

import MediaQuery from "../../../layout/MediaQuery";
import Tagline from "../../../texts/Tagline";
import Content from "../../../layout/Content/Content";
import Headline from "../../../texts/Headline";
import Description from "../../../texts/Description";

const Container = Styled.div`
  margin: ${(props) => (props.marginTop ? props.marginTop : 0)} 0 ${(props) =>
  props.marginBottom ? props.marginBottom : "2rem"} 0;
  overflow: hidden; // fix for columns overflow
  
    ${Media.greaterThan("700px")`
     margin: ${(props) => (props.marginTop ? props.marginTop : 0)} 0 ${(
      props
    ) => (props.marginBottom ? props.marginBottom : "18rem")} 0;
  `}
`;

const Columns = Styled.div`
   ${Media.greaterThan("700px")`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `}
  
  ${Media.greaterThan("large")`
    height: 55rem;
  `}
`;

const Column = Styled.div`
  position: relative;
  width: 100%;
  
  ${Media.greaterThan("700px")`
     padding-right: 3rem;
     width: 50%;
  `}
  
  ${Media.greaterThan("medium")`
    width: 45%;
  `}
`;

const ColumnIllustration = Styled(Column)`
  width: 100%;
  height: 42rem;
  display: flex;
  align-items: center;
  padding-right: 0;
      
  ${Media.greaterThan("700px")`
      height: auto;
      width: 50%;
      margin-top: 0;
  `}
  
  position: relative;
`;

const Text = Styled.div`
  max-width: 100%;
  
  ${Media.greaterThan("700px")`
      width: 43rem;
  `}

  ${Media.greaterThan("large")`
    width: calc(43rem + 9.5rem);
    padding-left: 9.5rem;
  `}
`;

const DescriptionExtended = Styled(Description)`

  ${Media.greaterThan("medium")`
    margin-bottom: 0;
  `}
`;

const ImageMobile = Styled(Image)`
  width: 34rem;
  margin: 0 0 3rem 0;
`;

const ImageWrapper = Styled.div`
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  
  ${Media.greaterThan("medium")`
    top: 50%;
  `}
  
  ${Media.greaterThan("large")`
    left: 50%;
  `}
  
  ${Media.greaterThan("1400px")`
    left: 45%;
  `}
`;

const ImageDesktop = Styled(Image)`
  width: 36.3rem;
  max-width: calc(100% - 0.75rem);
  
  ${Media.greaterThan("medium")`
    max-width: 100%;
  `}

  ${Media.greaterThan("1024px")`
    width: 40rem;
  `}
 
  ${Media.greaterThan("large")`
    width: 50rem;
  `}
  
    ${Media.greaterThan("1400px")`
    width: 66rem;
  `}
`;

const Box = Styled.div`
  width: 32rem;
  height: 24rem;
  margin: 0 auto;
  background-color: ${(props) => props.theme.colors.gray.lighter};
  
   ${Media.greaterThan("700px")`
    width: 100%;
    margin: 0;
  `}
  
  ${Media.greaterThan("1400px")`
    height: 39rem;
  `}
`;

const TaglineExtended = Styled(Tagline)`
  color: #7f7f7f;
`;

type HomeFeedbackProps = {
  tagline?: string;
  headline: string;
  description: string;
  imageA: GatsbyImageProps;
  imageB: GatsbyImageProps;
  imageAlt: string;
  marginTop?: string;
  marginBottom?: string;
  tagAsTitle?: boolean;
};

export default ({
  tagline,
  headline,
  description,
  imageA,
  imageB,
  imageAlt,
  marginTop,
  marginBottom,
  tagAsTitle,
}: HomeFeedbackProps) => (
  <Container marginTop={marginTop} marginBottom={marginBottom}>
    <Content>
      <Columns>
        <Column>
          <Text>
            {tagline && tagAsTitle && (
              <TaglineExtended as="h1">{tagline}</TaglineExtended>
            )}
            {tagAsTitle && <Headline as="h2">{headline}</Headline>}

            {tagline && !tagAsTitle && (
              <TaglineExtended>{tagline}</TaglineExtended>
            )}
            {!tagAsTitle && <Headline>{headline}</Headline>}

            <DescriptionExtended>{description}</DescriptionExtended>
          </Text>
        </Column>

        <ColumnIllustration>
          <Box />
          <ImageWrapper>
            <MediaQuery lessThan="1024px">
              <ImageMobile
                fluid={"fluid" in imageB ? imageB.fluid : null}
                alt={imageAlt}
              />
            </MediaQuery>

            <MediaQuery greaterThan="1024px">
              <ImageDesktop
                fluid={"fluid" in imageA ? imageA.fluid : null}
                alt={imageAlt}
              />
            </MediaQuery>
          </ImageWrapper>
        </ColumnIllustration>
      </Columns>
    </Content>
  </Container>
);
